import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GiBookmarklet } from 'react-icons/gi';
// import Newsletter from '../../components/newsletter/NewsletterSignUp';
import ArbutusFooter from '../../components/UI/ArbutusFooter';

import { WEBSITE_URL} from '../../globals';


//import { POST_DATA } from './InstagramPosts';

import styles from './Blog.module.css';

const InstagramPost = ({ post }) => {

    return (
        <article className={styles.InstagramPostArticle}>
            <div>
                <picture>
                    <source media="(min-width:1500px)" srcset="/images/instagram-posts/wisdom_of_your_body_large.webp" type="image/webp" />
                    <source media="(min-width:800px)" srcset="/images/instagram-posts/wisdom_of_your_body_medium.webp" type="image/webp" />
                    <source media="(min-width:200px)" srcset="/images/instagram-posts/wisdom_of_your_body_small.webp" type="image/webp" />
                    <img
                        src="/images/instagram-posts/wisdom_of_your_body_large.jpg"
                        alt="Josh sitting meditating with the text: Listen to the Wisdom of your Body"
                        loading="lazy"

                    />
                </picture>
            </div>
            <div>
                <h2>{post.title}</h2>
                {post.content.p.map(( text, index ) => (
                    <p>{text}</p>
                ))}
            </div>
        </article>
    );
};

const Blog = ({registerPage, connectivity, noJS}) => {

    // when the component mounts, register the header
    React.useEffect(() => {
        registerPage({
            header: {
                title: 'Blog',
                splash: {
                    tint: 'LIGHT',
                    containsTitle: true
                }
            },
            footerTint: false
        });
    }, [registerPage]);

    return(
        <div className={styles.Page}>
            <Helmet>
                <title>Blog | Eyes of Love</title>
                <meta name="description" content="My blog is where I will share new ideas with you, explore meaningful questions, and give you more insight into how I can support you." />
                <meta name="keywords" content="eyes of love, josh funfer, psychology, blog, psychotherapy" />

                <link rel="canonical" href={`${WEBSITE_URL}/blog/`} />

                <meta name="og:title" content="The writings and explorations of Josh Funfer" />
                <meta name="og:url" content={`${WEBSITE_URL}/blog`} />
                {/* <meta property="og:image" content={`${WEBSITE_URL}/images/OGImage.jpg`} /> */}
                <meta property="og:description" content="My blog is where I will share new ideas with you, explore meaningful questions, and give you more insight into how I can support you." />
                <meta property="og:type" content="website" />
            </Helmet>

            <div>
                <div className="container">
                    <h1>Eyes of Love Blog</h1>
                    <div className={styles.BlogIcon}><GiBookmarklet /></div>
                    <p>I'm starting a blog! I haven't written any posts yet, but I will be working on some in the coming months.</p>
                    <p>Stay tuned!</p>
                </div>

                {/* <div>
                    {POST_DATA.map((item, index) => (
                        <InstagramPost key={item.id} post={item} />
                    ))}
                </div> */}

                {/* {connectivity && !noJS ?
                    <div className={[styles.NewsletterSignup, "container"].join(" ")}>
                        <p>Want blog updates?</p>
                        <h2>Join my newsletter</h2>
                        <Newsletter topic="blog" />
                    </div>
                : null } */}
            </div>

            <div className={styles.FooterHolder}>
                <ArbutusFooter />
            </div>
        </div>
    );
};

export default Blog;