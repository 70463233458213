import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

import styles from './Header.module.css';


class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            scrolledHeader: false
        }
    }


    timer = null;
    headerRef = null;
    isSnapping = false;

    determineHeaderStyle = () => {
        let hs = window.pageYOffset > 100;

        if(hs || this.isSnapping) {
            this.headerRef.classList.add(styles.HeaderScrolled);
        }
        else {
            this.headerRef.classList.remove(styles.HeaderScrolled);
        }
    }

    throttleScrollHandler = () => {
        if(this.timer) return;
        
        this.timer = setTimeout(() => {
            this.determineHeaderStyle();
            this.timer = null;
        }, 300);
    }

    componentDidMount() {
        if(navigator.userAgent === "ReactSnap") this.isSnapping = true;
        this.headerRef = document.getElementById("main-header");
        this.headerRef.classList.add(styles.Header);
        window.addEventListener("scroll", this.throttleScrollHandler, {passive: true});
        this.determineHeaderStyle();
    }




    // non-state variable for if the header is scrolled, used for fast retrieval during page scroll events
    headerIsScrolled = false;

    // function for determining if the page is scrolled enough to show the 'scrolled header' style
    // only used when page has a splash screen causes a transparent header.
    determineHeaderStyle2 = () => {
        // hs is true when the page is scrolled past 200 scroll units
        const hs = window.scrollY > 200;
        // if we need to update the header style (because hs and headerIsScrolled is different)
        // then update scolledHeader state and headerIsScrolled
        if(this.headerIsScrolled !== hs) {
            this.setState({scrolledHeader: hs});
            this.headerIsScrolled = hs;
        }
    }

    // Effect for when the header properties change (i.e page change)
    // it adds an event listener to page scroll if the page has a spash image
    // otherwise it removes the listener 
    // componentDidMount() {
    //     console.log("noJS is: ", this.props.noJS);
    //     console.log("scrolledHeader is: ", this.state.scrolledHeader);
    //     window.addEventListener("scroll", this.determineHeaderStyle, false);
    //     this.determineHeaderStyle();
    // };


    // function for toggling the mobile menu open and closed. Used when the user clicks the hamburger icon.
    toggleMobileMenu = () => {
        this.setState(prevState => ({menuOpen: !prevState.menuOpen}));
    }

    // function for closing the mobile menu. Used when a user clicks a link in the header
    closeMobileMenu = () => {
        this.setState({menuOpen: false})
    }

    render() {

        return(
            <header id="main-header">
                <div className={styles.Brand}>
                    <div className={(this.props.properties.splash && this.props.properties.splash.isHome) ? [styles.Logo, styles.HideTitle].join(" ") : styles.Logo}></div>
                    <div className={styles.Title}>
                        <h1 className={this.props.properties.splash && this.props.properties.splash.containsTitle ? styles.HideTitle : null}>Eyes of Love</h1>
                        {this.props.properties.title ? <h2 className={this.props.properties.splash && this.props.properties.splash.containsTitle ? styles.HideTitle : null}>{this.props.properties.title}</h2> : null }
                    </div>
                </div>
                <span className={styles.MenuBars} onClick={this.toggleMobileMenu}><FaBars /></span>
                <nav className={this.state.menuOpen ? styles.Open : null}>
                    <span className={styles.CloseButton} onClick={this.toggleMobileMenu}><FaTimes /></span>
                    <NavLink to="/" title='Home' onClick={this.closeMobileMenu}>Home</NavLink>
                    <NavLink to="/approach" title="Learn about my approach" onClick={this.closeMobileMenu}>My Approach</NavLink>
                    <NavLink to="/about" title="Discover my story" onClick={this.closeMobileMenu}>My Story</NavLink>
                    <NavLink to="/faq" title="Frequently asked questions about psychotherapy" onClick={this.closeMobileMenu}>FAQ</NavLink>
                    <NavLink to="/events" title="Discover Eyes of Love's events" onClick={this.closeMobileMenu}>Events</NavLink>
                    <NavLink to="/blog" title="Explore new ideas in my blog" onClick={this.closeMobileMenu}>Blog</NavLink>
                    <NavLink to="/contact" title="Contact Josh" onClick={this.closeMobileMenu}>Contact</NavLink>
                </nav>

            </header>
        );
    }
}
// const Header = ({ properties, noJS }) => {


//     // set up state: menuOpen = if the mobile menu is open, scolledHeader = if the page is scrolled (to style header differently)
//     const [menuOpen, setMenuOpen] = React.useState(false);
//     const [scrolledHeader, setScrolledHeader] = React.useState(false);

//     // non-state variable for if the header is scrolled, used for fast retrieval during page scroll events
//     let headerIsScrolled = false;

//     // componentDidMount() {
//     //     window.addEventListener("scroll", determineHeaderStyle)
//     // }

//     // function for determining if the page is scrolled enough to show the 'scrolled header' style
//     // only used when page has a splash screen causes a transparent header.
//     function determineHeaderStyle() {
//         // hs is true when the page is scrolled past 200 scroll units
//         const hs = window.scrollY > 200;
//         // if we need to update the header style (because hs and headerIsScrolled is different)
//         // then update scolledHeader state and headerIsScrolled
//         if(headerIsScrolled !== hs) {
//             setScrolledHeader(hs);
//             headerIsScrolled = hs;
//         }
//     }

//     // Effect for when the header properties change (i.e page change)
//     // it adds an event listener to page scroll if the page has a spash image
//     // otherwise it removes the listener 
//     React.useEffect(() => {

//         window.addEventListener("scroll", determineHeaderStyle, false);
//         determineHeaderStyle();
//         // ESLint compains about determineHeaderStyle not listed as dependancy for this effect,
//         // I am silencing this line as I truley only want this effect launched on mount.
//         // eslint-disable-next-line
//     }, []);


//     // function for toggling the mobile menu open and closed. Used when the user clicks the hamburger icon.
//     const toggleMobileMenu = () => {
//         setMenuOpen(!menuOpen);
//     }

//     // function for closing the mobile menu. Used when a user clicks a link in the header
//     const closeMobileMenu = () => {
//         setMenuOpen(false);
//     }

//     // set up the appropreate Header classes based on page scroll in the headerClassArray array.
//     let headerClassArray = [styles.Header];
//     if(!properties.splash || scrolledHeader || noJS) headerClassArray.push(styles.HeaderScrolled);
//     if(properties.splash?.tint === 'LIGHT') headerClassArray.push(styles.HeaderLight);

//     return(
//         <header className={headerClassArray.join(" ")}>
//             <div className={styles.Brand}>
//                 <div className={(properties.splash && properties.splash.isHome) ? [styles.Logo, styles.HideTitle].join(" ") : styles.Logo}></div>
//                 <div className={styles.Title}>
//                     <h1 className={properties.splash && properties.splash.containsTitle ? styles.HideTitle : null}>Eyes of Love</h1>
//                     {properties.title ? <h2 className={properties.splash && properties.splash.containsTitle ? styles.HideTitle : null}>{properties.title}</h2> : null }
//                 </div>
//             </div>
//             <span className={styles.MenuBars} onClick={toggleMobileMenu}><FaBars /></span>
//             <nav className={menuOpen ? styles.Open : null}>
//                 <span className={styles.CloseButton} onClick={toggleMobileMenu}><FaTimes /></span>
//                 <NavLink to="/" title='Home' onClick={closeMobileMenu}>Home</NavLink>
//                 <NavLink to="/approach" title="Learn about my approach" onClick={closeMobileMenu}>My Approach</NavLink>
//                 <NavLink to="/about" title="Discover my story" onClick={closeMobileMenu}>My Story</NavLink>
//                 <NavLink to="/faq" title="Frequently asked questions about psychotherapy" onClick={closeMobileMenu}>FAQ</NavLink>
//                 <NavLink to="/events" title="Discover Eyes of Love's events" onClick={closeMobileMenu}>Events</NavLink>
//                 <NavLink to="/blog" title="Explore new ideas in my blog" onClick={closeMobileMenu}>Blog</NavLink>
//                 <NavLink to="/contact" title="Contact Josh" onClick={closeMobileMenu}>Contact</NavLink>
//             </nav>

//         </header>
//     );

// };

export default Header;