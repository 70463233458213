import React from 'react';
import { Helmet } from 'react-helmet-async';

import HolisticHealing from '../../components/UI/icons/HolisticHealing';
import Integration from '../../components/UI/icons/Integration';
import Relational from '../../components/UI/icons/Relational';
import Intuitive from '../../components/UI/icons/Intuitive';
import ClientCentered from '../../components/UI/icons/ClientCentered';
import Support from '../../components/UI/icons/Support';
import SelfHonesty from '../../components/UI/icons/SelfHonesty';

import SplashDownChevron from '../../components/UI/SplashDownChevron';

import styles from './Approach.module.css';
import ArbutusFooter from '../../components/UI/ArbutusFooter';
import Authenticity from '../../components/UI/icons/Authenticity';

import { WEBSITE_URL } from '../../globals';

const offerings = [
    {
        icon: Integration,
        title: "Integration",
        name: 'integration',
        text: [
            "By recognizing, accepting, and ultimately integrating, all aspects of who we are, we can return to a state of essential Wholeness.",
            "When we look within through eyes of love, we can begin to shine the light of Awareness on all the parts of the mind that have remained hidden within the darkness of ignorance. This takes courage. It is a vulnerable undertaking. However, the rewards are incredible.",
            "Together, we can look with love upon all the places in the mind where fear has made a home. When we give a voice to these frightened parts of ourselves, the outdated beliefs that keep them alive begin to transform. When we recognize our old beliefs, habits, and survival mechanisms that no longer serve us, we can then say \"thank you for serving me in the past but I no longer need you\" and finally let them go. This opens the way to be fully present, free, and alive."
        ]
    },
    {
        icon: Authenticity,
        title: 'Authenticity',
        name: 'authenticity',
        text: [
            "This is a space where we can be real together.",
            "In most social situations there is pressure to be at least somewhat inauthentic. For instance, we have all been conditioned to respond in a polite and reserved way to the question \"how are you?\". As a result, so many of us spend our lives self-censoring in order to make the people around us feel more comfortable. In our work together, I will do all I can to show you that you can be completely authentic with me. I am committed to being real and authentic with you as well. Let us see what amazing things can happen when two people can be completely genuine with one another."
        ]
    },
    {
        icon: SelfHonesty,
        title: 'Self-Honesty',
        name: 'selfHonesty',
        text: [
            () => (<span>Speak true &rarr; Live true &rarr; See true.</span>),
            "When we are truthful with ourselves and others, we naturally begin to live more honestly. As we start to live an honest life, we are then able to see more and more clearly what is most important to us.",
            "In our work together, we will look honestly at your thoughts, beliefs, emotions, and physical sensations so that you can start to practice this honest self-awareness in every aspect of your life.",
            "In order to be honest with the people around us, we first need to be honest with ourselves. However, this self-honesty must be accompanied by self-forgiveness. One of the reasons why it can be so hard to look at ourselves honestly is that it is so easy to judge and criticize ourselves. Whereas if we look lovingly upon what we discover about ourselves, we can start to forgive the mistakes we’ve made, accept our perceived shortcomings, and learn to see the beauty in our imperfection."
        ]
    },
    {
        icon: Support,
        title: 'Support, Care, & Guidance',
        name: 'support',
        text: [
            "I am here to support you as a fully-present, caring, and non-judgmental companion on your journey of self-discovery.",
            "It can be terrifying and overwhelming to face our fears, insecurities, and traumas alone. However, with support, anything is possible. If we choose to work together, I promise you that I will be right by your side to help you listen to, and work with, whatever arises for you – from deep-rooted fears to unexpected joys. We are in this together and you are not alone."
        ]
    },
    {
        icon: ClientCentered,
        title: 'Client Centered',
        name: 'clientCentered',
        text: [
            "I follow your lead and meet you where you are.",
            "You are in charge of your therapy. I will never do anything without your permission or guide you anywhere you do not want to go. I may invite you to explore a certain feeling, sensation, thought, or memory, but it is always up to you to decide whether or not to follow this invitation. I will never force you beyond what you are comfortable with. I may ask you to be right on the edge of your comfort zone, but I will never push you past what you are willing and able to do. You are the expert of your own experience and I fully trust you to know what is best for you."
        ]
    },
    {
        icon: HolisticHealing,
        title: 'Holistic Healing',
        name: 'holisticHealing',
        text: [
            "The body, the heart, the mind, and the soul are all integral parts of who we are.",
            "None of these aspects of ourselves exist in isolation. So, in order to heal a wound in one area, we may need to keep site of all aspects and work with them in a harmonious way.",
            "For example, if you consistently feel anxious, I might invite us to explore how this anxiety lives in your body, your heart, your mind, and your soul. It may manifest in your body through physical sensations like increased heart rate and behaviours like hypervigilance. It may express itself in your heart as emotions like shame. It may come through your mind as stressful thoughts and worries about the past or the future, as well as, harmful beliefs about yourself. And it may affect your soul by suppressing your creativity and cutting you off from meaningful social connections. It is only by looking at how the anxiety affects your entire system that we can begin to discover what this anxious part of you needs in order to heal."
        ]
    },
    {
        icon: Relational,
        title: 'Relational Meditation',
        name: 'relationalMeditation',
        text: [
            "Sitting together in Presence.",
            "In solitary meditation, the goal is usually to bring mindfulness to all of the thoughts, emotions, and physical sensations that naturally arise in the present moment. In relational meditation, we will bring the same mindfulness to our exploration of your present-moment experience together. Through this, we can begin to feel into the collective field of awareness that we share. Sitting with another person in this relational field can make it easier to access and stay with the difficult aspects of your experience that may be too overwhelming to explore alone."
        ]
    },
    {
        icon: Intuitive,
        title: 'Intuitive Healing',
        name: 'intuitiveHealing',
        text: [
            "Together we can begin to tap into our deepest wisdom.",
            "We can gradually learn to let go of our need to control, and instead allow the fullness of our being, the fullness of our compassion, and the depth of our wisdom to be expressed. Remarkable things happen when we get out of our own way and allow this essential wisdom that resides within to guide us. When we learn to let go of control then we can start to tap into and trust the wisdom and guidance of our body, our emotions, and our intuition."
        ]
    },
    
    
];


const Approach = ({ registerPage, noJS }) => {

    const [openOfferings, setOpenOfferings] = React.useState({});

    const [openAll, setOpenAll] = React.useState(false);


    // when the component mounts
    React.useEffect(() => {
        // register the header
        registerPage({
            header: {
                title: 'My Approach',
                splash: {
                    tint: 'LIGHT',
                    containsTitle: true
                }
            },
            footerTint: false
        });

        // map the offerings names to the openOfferings state object
        let o = {};
        for(let item of offerings) {
            o[item.name] = false;
        }
        setOpenOfferings(o);

        if(noJS) setOpenAll(true);
        else setOpenAll(false);
    }, [registerPage, noJS]);

    const openOfferingHandler = (name) => {

        let o = {...openOfferings};
        o[name] = true;
        setOpenOfferings(o);
    }

    const toggleOfferingHandler = (name) => {
        let o = {...openOfferings};
        o[name] = !o[name];
        setOpenOfferings(o);
    }


    return(
        <div className={styles.Page}>
            <Helmet>
                <title>My Approach | Eyes of Love</title>
                <meta name="description" content="Discover my approach to holistic counselling and learn more about how I work." />
                <meta name="keywords" content="psychotherapy, therapy, Josh Funfer, Salt Spring, holistic psychotherapy" />

                <link rel="canonical" href={`${WEBSITE_URL}/approach/`} />

                <meta name="og:title" content="My approach to holistic counselling" />
                <meta name="og:url" content={`${WEBSITE_URL}/approach`} />
                {/* <meta property="og:image" content={`${WEBSITE_URL}/images/OGImage.jpg`} /> */}
                <meta property="og:description" content="Discover my approach to holistic counselling and learn more about how I work." />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className={styles.Splash}>
                <div>
                    <h1 className="screen-reader-text">My Approach is Holistic Psychotherapy</h1>
                    <p className="screen-reader-text">Treating the body, mind, heart, and soul.</p>
                </div>
                <img src="/images/myApproach.svg" className={styles.MyApproachSVG} alt="" />
                <div className={styles.BrushedText}>
                    <p>The heart of this practice is cultivating the<br />ability to see ourselves through eyes of love.</p>
                </div>
                <SplashDownChevron light />
            </div>

            <div className={["container", styles.OfferingsContainer].join(" ")}>
                

                {offerings.map((item, index) => {
                    return(
                        <div className={styles.Offering} key={`offering-${index}`}>
                            <div className={styles.OfferingFlex}>
                                <div className={styles.OfferingIcon}>{item.icon()}</div>
                                <div className={styles.OfferingContent} onClick={openOfferingHandler.bind(this, item.name)}>
                                    <h3 className={styles.OfferingTitle}>{item.title}</h3>
                                    <div className={openOfferings[item.name] || openAll ? styles.ShowAll : null} title={!openOfferings[item.name] && !openAll ? "Click to expand" : null}>
                                        {item.text.map((text, tIndex) => {
                                            return(<p style={tIndex === 0 ? {fontWeight: 'bold'} : null}key={`offering-${index}-${tIndex}`}>{typeof(text) === 'string' ? text : text()}</p>);
                                        })}
                                    </div>
                                </div>
                            </div>
                            {!openAll ? <div className={styles.ReadMore}>
                                <button onClick={toggleOfferingHandler.bind(this, item.name)}>{openOfferings[item.name] ? "Show Less" : "Read More"}</button>
                            </div> : null }
                            {index + 1 === offerings.length ? null : <hr /> }
                        </div>
                    );
                })}
              
            </div>

            <div className={styles.AreasOfSpecializationContainer}>
                <h2>Areas of Specialization</h2>
                <div>
                   
                        <div className={styles.AreaOfSpecialization}>
                            <h3>Listening to our emotions</h3>
                            <p>I find great joy in helping people get in touch with their emotions. Together we can practice recognizing the emotions behind the stories you share with me about your life. Learning how to drop from the head into the heart was an integral part of my own inner journey.</p>
                        </div>
                        <div className={styles.AreaOfSpecialization}>
                            <h3>Low Self-Esteem</h3>
                            <p>Recognizing our habitual patterns of judging ourselves negatively and learning to view ourselves with more compassion is an essential part of eyes of love psychotherapy.</p>
                        </div>
                  
                    <div className={styles.AreaOfSpecialization}>
                        <h3>Existential Exploration</h3>
                        <p>I can help you examine your worldview, gain greater clarity on what you value most in life, and recognize both the beliefs that serve you and the ones that limit you.</p>
                    </div>

                    <ul>
                        <li>Depression</li>
                        <li>Anxiety</li>
                        <li>Self-Doubt/Self-Criticisn</li>
                        <li>Shame</li>
                        <li>Overthinking</li>
                        <li>Life Transitions</li>
                        <li>Relationship Struggles</li>
                        <li>Trauma</li>
                        <li>Grief and Loss</li>
                        <li>Addiction</li>
                    </ul>
                </div>
                
            </div>
            <div className={styles.FooterHolder}>
                <ArbutusFooter />
            </div>
        </div>
    );
};

export default Approach;