import React from 'react';

class PageErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null};
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };  
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // Store error in state so it can be shown to the user if they wish to report
        this.setState({error: error.message, errorInfo: errorInfo});
    }
    render() {
        // if there is an error return the fallback UI
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '50vh', marginTop: '100px'}}>
                    <h1>Something went wrong</h1>
                    <h3>This page crashed</h3>
                    <p>Please try <a href={window.location.href} title="Reload the page">reloading the page</a> to fix the error. If the issue persists, consider <a href="mailto:joe@arodny.com" title="Email the developer">contacting the site developer</a>.</p>
                    <hr />
                    <p>If you do contact the site developer, please include the following information</p>
                    <code style={{backgroundColor: '#eee', borderRadius: '5px', padding: '1em'}}>
                        <p>Time: {Date.now().toString()}</p>
                        <p>URL: {window.location.href}</p>
                        <p>Error: {this.state.error}</p>
                        <p>Error Details: {JSON.stringify(this.state.errorInfo)}</p>
                    </code>
                </div>
            );    
        }
        // else return this components children normally
        return this.props.children; 
    }
}

export default PageErrorBoundary;