import React from 'react';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';

import styles from './PhotoGallery.module.css';

const PhotoGallery = ({ library, open, clickAwayHandler }) => {

    const [ currentImageIndex, setCurrentImageIndex ] = React.useState(0);

    const disableScroll = () => {
        // Get the current page scroll position
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      
        // if any scroll is attempted, set this to the previous value
        window.onscroll = function() {
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    const enableScroll = () => {
        window.onscroll = null;
    }

    React.useEffect(() => {
        if(open) disableScroll();
        else enableScroll();
        return () => enableScroll();
    }, [open]);

    const changeImage = (direction) => {
        try {
            let index = currentImageIndex;

            if( direction === 'next' ) index++;
            else if ( direction === 'previous' ) index--;

            if ( index >= library.length) index = 0;
            else if( index < 0 ) index = library.length - 1;

            setCurrentImageIndex( index );

            // let img = document.getElementById(`image-thumbnail-${index}`);
            // if ( img ) {
            //     let bounds = img.getBoundingClientRect();
            //     let wWidth = window.innerWidth;

                
            //     if( ( wWidth - bounds.right > wWidth - 20) || ( bounds.right > window.innerWidth ) ) {
            //         img.scrollIntoView({inline: direction === 'next' ? "start" : "end", behavior: 'smooth'})
            //     }
            // }
        }
        catch(error) {
            console.error(error);
        }
    }

    const swipe_det = {
        sX: 0,
        sY: 0,
        eX: 0,
        eY: 0
    };

    const onPhotoTouchStart = (e) => {
        const t = e.touches[0];
        swipe_det.sX = t.screenX;
        swipe_det.sY = t.screenY;
    }

    const onPhotoTouchMove = (e) => {
        const t = e.touches[0];
        swipe_det.eX = t.screenX;
        swipe_det.eY = t.screenY;
        //e.preventDefault();
        
    }

    const onPhotoTouchEnd = (e) => {
        const deltaX = swipe_det.eX - swipe_det.sX;
        const deltaY = swipe_det.eY - swipe_det.sY;
        // Min swipe distance, you could use absolute value rather
        // than square. It just felt better for personnal use
        if (deltaX ** 2 + deltaY ** 2 < 60 ** 2) return;

        if (deltaY === 0 || Math.abs(deltaX / deltaY) > 1) {
            // Previous
            if( deltaX > 0 ) changeImage("previous");
            else changeImage("next");
        }
    }

    if (open) {
        return(
            <div className={styles.PhotoGalleryMainContainer}>
                <div className={[styles.CloseButtonRow, styles.IconButtons].join(" ")}>
                    <button onClick={clickAwayHandler} title="Close Gallery">
                        <FaTimes />
                    </button>
                </div>
                <div className={styles.ImageRow}>
                    <div className={[styles.IconButtons, styles.DesktopButtons].join(" ")}>
                        <button onClick={() => changeImage('previous')} title="Previous image">
                            <FaChevronLeft />
                        </button>
                    </div>
                    <div className={styles.ImageContainer}>
                        <img
                            src={library[currentImageIndex]?.regularURL}
                            alt={library[currentImageIndex]?.title}
                            onTouchStart={onPhotoTouchStart}
                            onTouchMove={onPhotoTouchMove}
                            onTouchEnd={onPhotoTouchEnd}
                        />
                        {library[currentImageIndex]?.description ?
                            <p>{library[currentImageIndex].description}</p>
                        : null}
                    </div>
                    <div className={[styles.IconButtons, styles.DesktopButtons].join(" ")}>
                        <button onClick={() => changeImage('next')} title="Next image">
                            <FaChevronRight />
                        </button>
                    </div>
                </div>

                <div className={[styles.ImageRow, styles.MobileControlSection].join(" ")}>
                    <div className={[styles.IconButtons].join(" ")}>
                        <button onClick={() => changeImage('previous')}>
                            <FaChevronLeft />
                        </button>
                    </div>
                    {library[currentImageIndex]?.description ? 
                        <p>{library[currentImageIndex].description}</p>
                    : null}
                    <div className={[styles.IconButtons].join(" ")}>
                        <button onClick={() => changeImage('next')}>
                            <FaChevronRight />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    else return null;
};

export default PhotoGallery;