import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FaYoutube } from 'react-icons/fa';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { IoWarningOutline } from 'react-icons/io5';
// import { VscLoading } from 'react-icons/vsc';
// import axios from 'axios';

import styles from './Events.module.css';

import { WEBSITE_URL } from '../../globals';

// import TextInput from '../../components/UI/TextInput';
// import SuccessCheckmark from '../../components/UI/SuccessCheckmark'
import ArbutusFooter from '../../components/UI/ArbutusFooter';


const Event = ({ registerPage, connectivity, noJS, history }) => {

    // const event = {
    //     name: 'Seeing Through Eyes of Love',
    //     slug: 'seeing-through-eyes-of-love',
    //     date: Date.now,
    // }

    // const [formSent, setFormSent] = React.useState(false);
    // const [formError, setFormError] = React.useState(null);

    // // when the component mounts, register header information
    // React.useEffect(() => {
    //     registerPage({
    //         header: {
    //             title: `Events`,
    //             splash: {
    //                 tint: 'LIGHT',
    //                 containsTitle: true
    //             }
    //         },
    //         footerTint: true
    //     });
    // }, [registerPage]);

    React.useEffect(() => {
        let hash = history.location.hash;
        if(hash) document.getElementById(hash.slice(1))?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }, [history])

    // const submitRegistrationHandler = ( values, { setSubmitting, setErrors } ) => {

    //     console.log(values);

    //     // Post the values to the api
    //     axios.post(`${API_URL}/events/register`, {
    //         ...values,
    //         event: event.slug,
    //         privacyPolicyAgreed: values.privacyPolicyAgreed ? 1 : 0
    //     })
    //     // if Successful then set formSent to true
    //     .then(response => {
    //         setFormSent(true);
    //         // TODO: fix this
    //         //document.getElementById("after-contact-scroll")?.scrollIntoView();
    //     })
    //     // if the request failed
    //     .catch(error => {
    //         // If it failed with status 422 then data validation failed.
    //         // Loop through the server response and set the appropriate error messages
    //         if(error.response && error.response.status === 422) {
    //             console.log(error.response.data);
    //             let o = {};
    //             for(let e of error.response.data.errors) {
    //                 o[e.param] = e.msg;
    //             }
    //             setErrors(o);
    //             setFormError("One or more fields are invalid; please correct them before proceeding");
    //         }
    //         // Else some other network error occured. Show a generic error message on the form 
    //         else {
    //             setFormError("Oops, something went wrong. Please try again later.");
    //         }

    //         // scroll up to the top of the form to show error message
    //         document.getElementById("registration").scrollIntoView({behavior: 'smooth'});

    //         // Finally reset the captcha
    //         //captchaRef.current.resetCaptcha();
    //     })
    //     // Finally set submitting to false
    //     .finally(() => {
    //         setSubmitting(false);
    //     });
    // }

    // const formik = useFormik({
    //     initialValues: {
    //         fname: '',
    //         lname: '',
    //         email: '',
    //         privacyPolicyAgreed: false,
    //     },
    //     onSubmit: submitRegistrationHandler,
    //     validationSchema: Yup.object().shape({
    //         fname: Yup.string().min(2, "Must be 2 characters or more").max(100, "Must be 100 characters or less"),
    //         lname: Yup.string().min(2, "Must be 2 characters or more").max(100, "Must be 100 characters or less"),
    //         email: Yup.string().required("Required Field").email('Invalid Email').min(5, "Must be 5 characters or more").max(150, "Must be 150 characters or less"),
    //         privacyPolicyAgreed: Yup.bool().required().oneOf([true], "You must accept the privacy policy to continue")
    //     })
    // });

    return (
        <div className={styles.Page}>
            <Helmet>
                <title>Seeing Through Eyes of Love | Events | Eyes of Love</title>
                <meta name="description" content="Seeing Through Eyes of Love - A Practice for Cultivating Self-Awareness & Self-Acceptance, is an online talk offered for free by Josh Funfer on Friday, July 30th at 5pm PST." />
                {/* <meta name="keywords" content="eyes of love, events, event, seminars, seminar, psychotherapy, therapy, psychology" /> */}

                <link rel="canonical" href={`${WEBSITE_URL}/events/seeing-through-eyes-of-love`} />

                <meta name="og:title" content="Seeing Through Eyes of Love" />
                <meta name="og:url" content={`${WEBSITE_URL}/events`} />
                <meta property="og:image" content="https://arodny-sites-uploads.s3.ca-central-1.amazonaws.com/www.eyesoflove.ca/uploads/2021/07/seeing-through-eyes-of-love.png" />
                <meta property="og:description" content="Seeing Through Eyes of Love, A Practice for Cultivating Self-Awareness & Self-Acceptance, is an online talk offered for free by Josh Funfer on Friday, July 30th at 5pm PST." />
                <meta property="og:type" content="website" />

                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "Seeing Through Eyes of Love",
                        "startDate": "2021-07-30T17:00:00-07:00",
                        "endDate": "2021-07-30T19:00:00-07:00",
                        "eventStatus": "https://schema.org/EventScheduled",
                        "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
                        "location": {
                            "@type": "VirtualLocation",
                            "url": "https://www.eyesoflove.ca/events/seeing-through-eyes-of-love/"
                        },
                        "image": [
                            "https://arodny-sites-uploads.s3.ca-central-1.amazonaws.com/www.eyesoflove.ca/uploads/2021/07/seeing-through-eyes-of-love.png"
                        ],
                        "description": "Seeing Through Eyes of Love - A Practice for Cultivating Self-Awareness & Self-Acceptance, is an online talk offered for free by Josh Funfer on Friday, July 30th at 5pm PST.",
                        "offers": {
                            "@type": "Offer",
                            "url": "https://www.eyesoflove.ca/events/seeing-through-eyes-of-love/",
                            "price": "0",
                            "priceCurrency": "CAD",
                            "availability": "https://schema.org/InStock"
                        },
                        "performer": {
                            "@type": "Person",
                            "name": "Josh Funfer"
                        },
                        "organizer": {
                            "@type": "Person",
                            "name": "Josh Funfer",
                            "url": "https://www.eyesoflove.ca/"
                        }
                    }`}
                </script>

            </Helmet>
            <div className="container">
                <div className={styles.BackButtonDiv}>
                    <Link to="/events" title="Back to all events page" className={[styles.ButtonLink, styles.ButtonLinkOutlined].join(" ")}>&lt; Back to Events</Link>
                </div>
                {/* <h1>{event.name}</h1> */}
                <div className="row" style={{marginTop: '2em'}}>
                    <div className={styles.EventTitleBlock} style={{marginBottom: '0.7em', marginTop: '1.5em'}}>
                        <h1 style={{marginTop: '0px', fontSize: '150%'}}>
                            <span style={{display: 'block', fontFamily: 'Eagle Lake'}}>Seeing Through Eyes of Love</span>
                            <span style={{display: 'block', fontSize: '0.8em', fontWeight: 'normal', fontFamily: 'Work Sans', marginTop: '0.5em'}}>A Practice for Cultivating Self-Awareness &amp; Self-Acceptance</span>
                        </h1>
                        <span style={{display: 'inline-block', fontFamily: 'Work Sans', fontSize: '1em', color: 'green', fontWeight: 'bold', border: '2px solid green', borderRadius: '25px', padding: '3px 15px', marginRight: '0.5em'}}>FREE</span>
                        <span style={{display: 'inline-block', fontFamily: 'Work Sans', fontSize: '1em', color: 'green', fontWeight: 'bold', border: '2px solid green', borderRadius: '25px', padding: '3px 15px'}}>Live Online Talk</span>
                    </div>
                    <div className={[styles.EventDateBlock].join(" ")} style={{marginBottom: '0.7em', marginTop: '1.5em'}}>
                        <span style={{display: 'block', fontSize: '1.5em'}}>Fri, Jul 30, 2021</span>
                        <span style={{display: 'block', fontSize: '1.2em'}}>5:00 PM PST</span>
                        <span style={{display: 'block', fontSize: '1.25em'}}>Duration: 2 Hours</span>
                        {/* <Link to="/events" title="Register" className={[styles.ButtonLink].join(" ")} style={{marginTop: '1em'}} onClick={(e) => {
                            e.preventDefault();
                            document.getElementById('registration').scrollIntoView({behavior: 'smooth', block: 'center'});
                        }}>Register</Link> */}
                    </div>
                </div>
                <hr style={{ margin: '1em auto'}} />
                    

                    <a className={styles.YoutubeVideoLink}
                        href="https://www.youtube.com/watch?v=EbM1N0wJvGM"
                        target="_BLANK"
                        rel="noreferrer"
                        title="Watch a recording of this talk for free on Youtube"
                        style={{backgroundImage: "url('/images/Seeing_Through_Eyes_of_Love_Video_Thumbnail.webp')"}}>
                        <FaYoutube />
                    </a>


                <h2>Description</h2>
                <p>“Seeing Through Eyes of Love - A Practice for Cultivating Self-Awareness &amp; Self-Acceptance” is a talk that I will be offering online for free on Friday, July 30th at 5pm PST.</p>
                <p>I am giving this talk because I want to share a practice with you - the practice of seeing yourself and the world around you through eyes of love.</p>
                <p>This practice is very close to my heart. It is both the foundation for my holistic counselling work, and it is the core of my spiritual practice.</p>
                <p>In this talk, I will tell you about some of the most significant experiences, lessons, and insights in my life that helped me discover what it means to see through eyes of love. I will describe how viewing yourself through eyes of love can help you begin to cultivate self-awareness and self-acceptance. I will also explain some of the most important aspects of this practice and give you 3 practical tools to help you bring it into your daily life. Finally, I will lead a 15-minute guided meditation to help you experience what it is like to see yourself through eyes of love.</p>
                <p>The talk will be 2 hours long, which includes a break in the middle, the guided meditation, and 30 minutes at the end for questions and discussion.</p>
                <p>If you would like to attend this talk, please enter your name and email address into the registration form below. You will then be sent a confirmation email with simple instructions for how to join the event. I look forward to seeing you there!</p>

                <h2 id="schedule">Schedule</h2>
                <ol className={styles.ScheduleList}>
                    <li>Introduction (5 minutes)</li>
                    <li>Background for Eyes of Love (30 minutes)</li>
                    <li>Break (10 minutes)</li>
                    <li>Description of Eyes of Love (15 minutes)</li>
                    <li>Benefits of Seeing Through Eyes of Love (5 minutes)</li>
                    <li>Guided Meditation (15 minutes)</li>
                    <li>3 Practical Tools (10 minutes)</li>
                    <li>Questions &amp; Discussion (30 minutes)</li>
                </ol>
                {/* <hr style={{ margin: '2em auto 3em auto'}}/> */}
                

                {/* Registration Form
                {!formSent ? <div>
                    <h2 id="registration">Registration</h2>
                    {Boolean(formError) ? <div className={styles.FormError}>
                                    <span><IoWarningOutline /></span>
                                    <p>{formError}</p>
                                </div> : null }
                    <form name="event-registration-form" noValidate id="event-registration-form" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <TextInput
                                    name="fname"
                                    label="First Name"
                                    type="text"
                                    value={formik.values.fname}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.fname}
                                    error={formik.errors.fname}
                                     />
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    name="lname"
                                    label="Last Name"
                                    type="text"
                                    value={formik.values.lname}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.lname}
                                    error={formik.errors.lname}
                                     />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextInput
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.email}
                                    error={formik.errors.email} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <TextInput
                                    name="privacyPolicyAgreed"
                                    type="checkbox"
                                    value={formik.values.privacyPolicyAgreed}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.privacyPolicyAgreed}
                                    error={formik.errors.privacyPolicyAgreed}>
                                        <span>I have read and agree to the <a href="/privacy-policy" title="Open the privacy policy in a new tab" target="_BLANK" referrerPolicy="no-referrer">privacy policy</a>.</span>
                                </TextInput>
                            </div>
                        </div>

                        <div className={styles.SubmitRow}>
                            <div>{!formik.isValid ? <IoWarningOutline /> : null}</div>
                            <div><input type="submit" value={formik.isSubmitting ? "Registering..." : 'Register'} className={styles.SubmitButton} /></div>
                            <div>{formik.isSubmitting ? <VscLoading /> : null}</div>
                        </div>

                    </form>
                </div>
                :
                // This shows the checkmark when the form is sent
                <div style={{display: 'flex', flexDirection: 'column', maxHeight: '50vh', justifyContent: 'center', margin: '10px auto'}}>
                    <SuccessCheckmark />
                    <p style={{textAlign: 'center', marginTop: '0px', fontSize: '140%', color: '#666', fontWeight: 'bold'}}>You're Registered!</p>
                    <p style={{textAlign: 'center'}}>You will recieve an email with instructions to join the Zoom Event.</p>
                    <p style={{textAlign: 'center'}}>Please allow a few minutes for the email to arrive (check your spam or other inbox folders). If you have still not recieved the email after that time, please <Link to="/contact" title="Reach out through my contact page">reach out to me directly</Link> and I will send you instructions for joining the event.</p>
                    <p style={{textAlign: 'center'}}>Thank you!</p>
                    <p style={{textAlign: 'center'}}><Link to={`/events`} title="Back to events">Back to events</Link></p>
                </div> */}

            </div>

            <div className={styles.FooterHolder}>
                <ArbutusFooter tint />
            </div>
        </div>
    );
};

export default Event;