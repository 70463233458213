import React from 'react';
// import { FaMapMarked } from 'react-icons/fa';
// import { ImAppleinc } from 'react-icons/im';
import { FcGoogle } from 'react-icons/fc';

import { MAP_INFO } from '../globals';

const detectOS = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let os = null;

        if (/windows phone/i.test(userAgent)) {
            os = 'windows';
        }

        if (/android/i.test(userAgent)) {
            os = 'android';
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            os = 'ios';
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/Mac/.test(userAgent) && !window.MSStream) {
            os = 'mac';
        }
        return os;
};

const GoogleMapsIcons = () => {
    return(
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" role="img" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
           <path
     d="M 9.466,5.868 14.162,0.285 14.115,0.273 A 8.31,8.31 0 0 0 11.986,0 8.439,8.439 0 0 0 5.817,2.766 L 5.801,2.784 Z"
     id="path845"
     fill="#000080" />
  <path
     d="m 14.661,0.436 -3.838,4.563 a 0.295,0.295 0 0 1 0.027,-0.01 c 1.6,-0.551 3.403,0.15 4.22,1.626 0.176,0.319 0.323,0.683 0.377,1.045 0.068,0.446 0.085,0.773 0.012,1.22 L 15.456,8.896 19.292,4.335 A 8.382,8.382 0 0 0 14.67,0.439 Z"
     id="path843"
     fill="#0000ff" />
  <path
     d="M 5.492,3.149 5.489,3.153 C 3.542,5.619 3.208,9.033 4.372,11.923 L 9.157,6.234 9.099,6.184 Z"
     id="path841"
     fill="#ff0000" />
  <path
     d="m 8.545,7.705 -3.959,4.707 c 0.724,1.54 1.821,2.863 2.871,4.18 0.247,0.31 0.494,0.622 0.737,0.936 l 4.984,-5.925 -0.029,0.01 C 11.408,12.214 9.458,11.322 8.757,9.626 A 3.377,3.377 0 0 1 8.548,8.91 C 8.485,8.473 8.471,8.149 8.544,7.712 Z"
     id="path839"
     fill="#ffcc00" />
  <path
     d="m 19.527,4.799 c 1.212,2.608 0.937,5.678 -0.405,8.173 -1.101,2.047 -2.744,3.74 -4.098,5.614 -0.619,0.858 -1.244,1.75 -1.669,2.727 -0.141,0.325 -0.263,0.658 -0.383,0.992 -0.121,0.333 -0.224,0.673 -0.34,1.008 -0.109,0.314 -0.236,0.684 -0.627,0.687 H 11.998 C 11.532,23.999 11.419,23.47 11.303,23.113 11.019,22.239 10.722,21.4 10.284,20.588 9.774,19.644 9.139,18.771 8.494,17.917 Z"
     id="path4"
     fill="#008000" />
        </svg>
    );
};

const GenerateMapLinks = () => {
    let os = detectOS();

    // const aM = <a href={MAP_INFO.appleMaps} target="_BLANK" rel="noreferrer" title="Open this address in Apple Maps"><ImAppleinc /> Open in Apple Maps</a>;

    const gM = <a href={MAP_INFO.googleMaps} target="_BLANK" rel="noreferrer" title="Open this address in Google Maps">
        <FcGoogle /> {os === 'android' ? "Open in Google Maps Web" : "Open in Google Maps"}</a>;

    const geoM = <a href={MAP_INFO.geo} target="_BLANK" rel="noreferrer" title="Open this address in the Google Map App"><GoogleMapsIcons /> Open in Google Maps App</a>;

    // const openM = <a href={MAP_INFO.openStreetMap} target="_BLANK" rel="noreferrer" title="Open this address in OpenStreetMap"><FaMapMarked /> Open in OpenStreetMap</a>;

    return(
        <>
            {/* {os === 'mac' || os === 'ios' ? aM : null} */}
            {os === 'android' ? geoM : null}
            {gM}
            {/* {!os ? openM : null} */}
        </>
    );
};



export { GenerateMapLinks } 