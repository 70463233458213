import React from 'react';

import styles from './UI.module.css';

const TextInput = (props) => {

    // Determine the class to apply to the form input field.
    // Always apply .FormControl, and if the field has been touched (visited)
    // then check if the field has errors, if so add error styling, if not
    // add valid styling
    let inputClasses = [styles.FormControl];
    if(props.touched) {
        if(props.error) {
            inputClasses.push(styles.IsInvalid);
        }
        else {
            inputClasses.push(styles.IsValid);
        }
    }

    //Perform checks on input type and display fields appropriately

    // If type checkbox, then children are the checkbox's label (used so we can have links and such in checkbox's label)
    if(props.type === 'checkbox') {
        return(
            <div className={styles.FormGroup}>
                <div className={styles.FormCheck}>
                    <input
                        type="checkbox"
                        name={props.name}
                        id={props.name}
                        checked={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur} />
                    <label className={styles.FormLabel} htmlFor={props.name}>{props.children}</label>
                    {props.error && props.touched ? <div className={styles.InvalidFeedback}>{props.error}</div> : null }
                </div>
            </div>
        )
    }
    else if(props.type === 'textarea') {
        return(
            <div className={styles.FormGroup}>
                {props.label ? <label htmlFor={props.name} className={styles.FormLabel}>{props.label}</label> : null }
                <textarea id={props.name}
                    name={props.name}
                    className={inputClasses.join(" ")}
                    placeholder={props.placeholder}
                    rows={props.rows || "5"}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}></textarea>
                {props.error && props.touched ? <div className={styles.InvalidFeedback}>{props.error}</div> : null }
            </div>
        );
    }
    else {
        return(
            <div className={styles.FormGroup}>
                {props.label ? <label htmlFor={props.name} className={styles.FormLabel}>{props.label}</label> : null }
                <input id={props.name}
                    name={props.name}
                    type={props.type}
                    className={inputClasses.join(" ")}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur} />
                {props.error && props.touched ? <div className={styles.InvalidFeedback}>{props.error}</div> : null }
            </div>
        );
    }
}

export default TextInput;