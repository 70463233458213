import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import SplashDownChevron from '../../components/UI/SplashDownChevron';

import { WEBSITE_URL} from '../../globals';

import styles from './FAQ.module.css';

const faqData = [
    {
        id: 'q1',
        question: 'How do I get started?',
        answer: [
            () => (<span>We start with a free 30-minute consultation which can be done over the phone, on video-chat, or in-person at my office. Please reach out to schedule our initial consultation through email, call, text, or the built-in form, which can all be found on my <Link to="/contact" title="Discover the ways you can connect to me">contact page</Link>.</span>),
            "I would love to meet you and I am happy to tell you more about how I work. This consultation is an opportunity for us to get to know one another and determine if we would be a good fit. If after we meet you decide that you would prefer to work with another counsellor for any reason, I would be happy to give you a referral."
        ]
    },
    {
        id: 'q2',
        question: 'Do you offer therapy online?',
        answer: [
            "Yes. I offer both video-chat sessions and in-person counselling sessions at my office in Ganges on Salt Spring Island. We can discuss which of these options you would prefer during our initial consultation.",
            "I have taken all necessary precautions to reduce the spread of COVID-19 at my office by providing enough space for adequate social distancing and also by regularly cleaning and sanitizing the exposed surfaces."
        ]
    },
    {
        id: 'q3',
        question: 'What is your fee?',
        answer: [
            "My fee is $122 for a 50 minute session."
        ]
    },
    {
        id: 'q4',
        question: 'Do you offer a sliding scale fee?',
        answer: [
            "Yes I do. I have a limited number of sliding scale slots for clients with financial need."
        ]
    },
    {
        id: 'q5',
        question: 'Do you accept insurance?',
        answer: [
            "No, I do not accept insurance at this time."
        ]
    },
    {
        id: 'q6',
        question: 'Who do you support?',
        answer: [
            () => (<span>I work with adolescents and adults of all ages. I primarily offer one-on-one counselling sessions. Check my <Link to="/events" title="Visit my events page">events</Link> page for talks, workshops, and group offerings.</span>)
        ]
    },
    {
        id: 'q7',
        question: 'How long does each session last?',
        answer: [
            "Each session is 50 minutes long."
        ]
    },
    {
        id: 'q8',
        question: 'How often will we meet?',
        answer: [
            "We will meet once a week.",
            "Meeting consistently helps create a safe and solid container, which is essential for inner exploration and healing. It is especially important for us to meet weekly during the first 2 months of therapy while we build trust and get to know one another. After that, I am open to discussing the possibility of meeting every other week. However, I do not offer one-time only sessions."
        ]
    },
    {
        id: 'q9',
        question: 'How long will my therapy continue?',
        answer: [
            "My hope is that our therapeutic journey together will last for as long as it is benefiting you. You may get what you need from our work after a few months or it may continue to help you for many years."
        ]
    },
    {
        id: 'q10',
        question: 'What is your cancellation policy?',
        answer: [
            "If you need to cancel a scheduled session, please let me know at least 24 hours in advance. If you cancel our session less than 24 hours before our scheduled time then I will charge you the full fee for the session. I will waive the fee in the case of any emergency."
        ]
    },
    {
        id: 'q11',
        question: 'What is your approach?',
        answer: [
            () => (<span>My approach is holistic, relational, and based on cultivating self-awareness and self-acceptance. To find out more, please check out <Link to="/approach" title="Read my Approach">my approach page</Link>.</span>)
        ]
    },
    {
        id: 'q12',
        question: 'What are your credentials?',
        answer: [
            () => (<span>I am a <b>Registered Clinical Counsellor</b> of British Colombia with a master’s degree in Integral Counselling Psychology and a bachelor’s degree in Western Philosophy and Eastern Religious Studies with a minor in Psychology. Read <Link to="/about#qualifications" title="View my qualifications">my story</Link> for more information about my experience and background.</span>)
        ]
    },

];

const FAQ = ({registerPage, noJS}) => {

    // set up state. faqsOpen is an object that of which faqs are open, and openAll determines if all faqs should be displayed open.
    const [faqsOpen, setFaqsOpen] = React.useState({});
    const [openAll, setOpenAll] = React.useState(false);

     // when the component mounts
     React.useEffect(() => {
        // register the header
        registerPage({
            header: {
                title: 'FAQ',
                splash: {
                    tint: 'LIGHT',
                    containsTitle: true
                }
            },
            footerTint: false
        });

        // map the faq names to the openOfferings state object
        let o = {};
        for(let item of faqData) {
            o[item.id] = false;
        }
        setFaqsOpen(o);

        // if noJS then we're building, set all FAQs open so that non-js users see all content.
        if(noJS) setOpenAll(true);
    }, [registerPage, noJS]);

    const toggleFAQ = (name) => {
        let o = {...faqsOpen};
        o[name] = !o[name];
        setFaqsOpen(o);
    }

    return(
        <div className={styles.Page}>
            <Helmet>
                <title>FAQ | Eyes of Love</title>
                <meta name="description" content="Find answers to frequently asked questions about my practice and learn how to start therapy." />
                <meta name="keywords" content="psychotherapy, therapy practice, Josh Funfer, Salt Spring, faq, frequently asked questions" />

                <link rel="canonical" href={`${WEBSITE_URL}/faq/`} />

                <meta name="og:title" content="Frequently asked questions" />
                <meta name="og:url" content={`${WEBSITE_URL}/faq`} />
                {/* <meta property="og:image" content={`${WEBSITE_URL}/images/OGImage.jpg`} /> */}
                <meta property="og:description" content="Find answers to frequently asked questions about my practice and learn how to start therapy." />
                <meta property="og:type" content="website" />

            </Helmet>
            <div className={styles.BackgroundImage}></div>
            <div className={styles.Splash}>
                <h1>Frequently Asked Questions</h1>
                <SplashDownChevron light/>
            </div>

            <div className={[styles.FAQSection, 'container'].join(" ")}>
                
                {faqData.map((item) => {
                    return(
                        <div className={styles.FAQ} key={item.id}>

                                <h3 title={!openAll ? "Click to expand and reveal answer" : null} onClick={toggleFAQ.bind(this, item.id)} className={openAll ? styles.FAQTitleNonClickable : null}>
                                    <span>{item.question}</span>
                                    <span>{!openAll ? (faqsOpen[item.id] ? "- " : "+ ") : null}</span>
                                </h3>
                
                            <div className={faqsOpen[item.id] || openAll ? styles.ContentOpen : null}>
                                {item.answer.map((answer, aIndex) => {
                                    return (<p key={`${item.id}-${aIndex}`}>{typeof(answer) === 'string' ? answer : answer()}</p>);
                                })}
                            </div>
                        </div>
                    );
                })}
                <p>Is your question not answered here? Please feel welcome to <Link to="/contact" title="Get in touch with Josh">ask me directly</Link>.</p>
            </div>

            <div className={styles.ImageFooterHolder}>
                <div className={[styles.ImageFooter, "image-footer"].join(" ")}></div>
            </div>
        </div>
    );
};

export default FAQ;