import React from 'react';
import { FaFacebookSquare, FaInstagram, FaPhone } from 'react-icons/fa';
import { IoLocationSharp } from 'react-icons/io5';
import { MdMail } from 'react-icons/md';
import { Link } from 'react-router-dom';


import { CONTACT_INFO, MAP_INFO } from '../../globals';
import styles from './Footer.module.css';
import RccLogo from '../../assets/images/rcc_new.svg';

const Footer = ({ tint }) => {

    let footerStyles = [styles.Footer];
    if(tint) footerStyles.push(styles.FooterTint);

    return(
        <footer className={footerStyles.join(" ")}>
            <div className="container">
            <div className={styles.Social}>
                <a href="https://facebook.com/eyesoflovepsychotherapy" rel="noreferrer" target="_BLANK" title="Follow me on Facebook"><FaFacebookSquare /></a>
                <a href="https://instagram.com/eyesoflovepsychotherapy" rel="noreferrer" target="_BLANK" title="Follow me on Instagram"><FaInstagram /></a>
            </div>

            <div className={styles.SiteMap}>
                <div>
                    <a href="https://bc-counsellors.org" title="Visit the BC Counsellors Website" target="_BLANK" rel="noreferrer"><img src={RccLogo} width="150px" className={styles.RCCFooterLogo} alt="BC Registered Clinical Counsellor Logo" /></a>
                </div>
                <div>
                    <h3>Sitemap</h3>
                    <ul>
                        <li><Link to="/" title="Visit homepage">Home</Link></li>
                        <li><Link to="/about" title="Read my story">My Story</Link></li>
                        <li><Link to="/approach" title="Read my approach">My Approach</Link></li>
                        <li><Link to="/events" title="Discover Eyes of Love Events">Events</Link></li>
                    </ul>
                </div>
                <div>
                    <h3>Resources</h3>
                    <ul>
                        <li><Link to="/blog" title="Check out my blog">Blog</Link></li>
                        <li><Link to="/faq" title="Check out the frequently asked questions">FAQ</Link></li>
                        <li><Link to="/privacy-policy" title="View the privacy policy">Privacy Policy</Link></li>
                        <li><a href="/Consent-Agreement-Eyes-of-Love.pdf" title="Open the consent agreement PDF in a new tab" target="_BLANK" rel="noreferrer">Consent Agreement [PDF]</a></li>
                    </ul>
                </div>
                <div>
                    <h3>Contact</h3>
                    <ul>
                        <li><MdMail /> <a href={`mailto:${CONTACT_INFO.email}`} title="Email Josh">{CONTACT_INFO.email}</a></li>
                        <li><FaPhone /> <a href={`tel:${CONTACT_INFO.phone.value}`} title="Call Josh">{CONTACT_INFO.phone.label}</a></li>
                        <li><IoLocationSharp /><a href={MAP_INFO.googleMaps} title="Open this address in a new Google Maps tab" target="_BLANK" rel="noreferrer">338 Lower Ganges Road, Unit 208,<br />Salt Spring Island, BC, Canada, V8K 2V3.</a></li>
                    </ul>
                </div>
            </div>

            <p className={styles.Copyright}>Copyright &copy; Eyes of Love Psychotherapy, { new Date().getFullYear()}. All rights reserved.</p>
            </div>
        </footer>

    )
}

export default Footer;