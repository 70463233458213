const Authenticity = () => {
    return(
<svg
   width="15mm"
   height="19mm"
   viewBox="0 0 7.2780327 28.045901">
  <g
     transform="translate(-103.0705,-45.2367)">
    <path
       style={{fill: '#000', strokeWidth: '0.264583'}}
       d="m 106.2455,72.3565 c 0,-0.5093 -0.068,-0.926 -0.1513,-0.926 -0.083,0 -0.675,0.3068 -1.315,0.6819 -1.0535,0.6175 -1.7087,0.6744 -1.7087,0.1486 0,-0.1081 0.7131,-0.6071 1.5847,-1.1089 1.3445,-0.774 1.5851,-1.0255 1.5875,-1.6593 v -0.747 l -1.4258,0.8158 c -1.1375,0.6508 -1.4554,0.7389 -1.5718,0.4356 -0.1658,-0.4321 -0.01,-0.5769 1.7409,-1.575 l 1.2567,-0.7182 v -8.0586 -8.0587 h -1.4746 c -1.0494,0 -1.4499,-0.095 -1.389,-0.3309 0.1331,-0.5151 3.2382,-6.0191 3.3957,-6.0191 0.1348,0 3.1721,5.9563 3.1721,6.2206 0,0.071 -0.5953,0.1294 -1.3229,0.1294 h -1.3229 v 8.0587 8.0586 l 1.2568,0.7182 c 1.7492,0.9996 1.9067,1.1427 1.7389,1.58 -0.1193,0.3109 -0.4223,0.2302 -1.5717,-0.4185 l -1.424,-0.8036 v 0.7464 c 0,0.6355 0.2326,0.8773 1.565,1.6267 1.0117,0.5691 1.5162,1.0076 1.4269,1.2403 -0.186,0.4847 -0.3098,0.461 -1.5367,-0.2947 -0.5821,-0.3585 -1.1476,-0.6555 -1.2568,-0.6601 -0.1091,0 -0.1984,0.4085 -0.1984,0.9178 0,0.7497 -0.1008,0.9261 -0.5292,0.9261 -0.4284,0 -0.5292,-0.1764 -0.5292,-0.9261 z m 2.1284,-22.1551 c 0,-0.077 -0.3817,-0.6856 -0.8339,-1.5814 l -0.8222,-1.6288 -0.8942,1.5702 c -0.4919,0.8635 -0.8841,1.4723 -0.886,1.5814 0,0.1092 0.7706,0.1985 1.7165,0.1985 0.9459,0 1.7198,-0.063 1.7198,-0.1399 z"
       id="path4930-9"
       />
  </g>
</svg>
    );
};

export default Authenticity;