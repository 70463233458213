import React, {useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { GiDirectionSigns} from 'react-icons/gi';
import { Link } from 'react-router-dom';

const PageNotFound = ({ registerPage }) => {
        useEffect(() => {
            registerPage({
                header: {
                    title: 'Page not Found',
                    splash: null
                },
                footerTint: false
            });
        }, [registerPage]);

    return(
        <div className="container">
            <Helmet>
                <title>404 | Page Not Found | Eyes of Love Psychotherapy</title>

                <link rel="canonical" href="" />
                <meta name="og:title" content="Page not found" />
                <meta name="og:url" content="" />
                <meta property="og:description" content="The link you requested could not be found" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="PageNotFound">
                <div className="icon">
                    <GiDirectionSigns />
                </div>
                <h1>404 - Page Not Found</h1>
                <p className="tagline">You've strayed from the path.</p>
                <p><Link to="/" title="Go to the home page">Return to the path (Home)</Link></p>
            </div>
        </div>
    );
}

export default PageNotFound;