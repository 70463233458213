import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Home from './views/home/Home';
import PageErrorBoundary from './components/errorboundaries/PageErrorBoundary';
import axios from 'axios';

import { API_URL, WEBSITE_URL } from './globals';
import Header from './containers/header/Header';
import PageNotFound from './views/PageNotFound';
import Contact from './views/contact/Contact';
import Footer from './containers/footer/Footer';
import ScrollToTop from './helpers/ScrollToTop';
import Approach from './views/approach/Approach';
import FAQ from './views/faq/FAQ';
import Events from './views/events/Events';
import Event from './views/events/Event';
import Blog from './views/blog/Blog';
import About from './views/about/About';
// import Subscriber from './views/newsletter/subscriber/Subscriber';

const PrivacyPolicy = React.lazy(() => import('./views/privacy/PrivacyPolicy'));


class App extends React.Component {

  // constructor for this class
  constructor(props) {
    // call parent constructor
    super(props);
    // set up state
    this.state = {
      headerProperties: {
        title: 'Eyes of Love Psychotherapy',
        splash: {
          tint: 'LIGHT',
          containsTitle: true
        }
      },
      footerTint: false,
      apiConnectivity: false,
      noJs: false
    };
  }

  // Function that gets run once when the component (app) first mounts
  componentDidMount() {
    // check if the api is connected
    this.checkConnectivity();

    // if the user agent is ReactSnap then we're building, set all offerings open so that non-js users see all content.
    try {
        if(navigator.userAgent === "ReactSnap") {
          this.setState({noJs: true});
        }
        else {
          this.setState({noJS: false});
        }
    } catch(e) {
        console.log("[App.js] Navigator User Agent blocked. Can not determine if JavaScript is enabled. Defaulting to no JavaScript.")
        this.setState({noJS: false});
    } 
  }

  // function for checking if the API is up and running, if not it sets state.apiConnectivity to false
  checkConnectivity = () => {
    axios.get(`${API_URL}/check-connectivity`)
      .then(() => {
        this.setState({apiConnectivity: true});
      })
      .catch(() => {
        this.setState({apiConnectivity: false});
      });
    // setTimeout(() => {
    //   console.error("check connectivity not implimented");
    //   this.setState({apiConnectivity: true});
    // }, 1000);
  }

  // handler for setting the header properties in state. It is passed to each page so that it may customize
  // the header on a per-page basis
  registerPageProperties = (properties) => {
    /*
      Properties is an object with the following structure
      {
        header: {
          title: <String> The title of the page
          splash: {
            tint: <String> One of ['DARK', 'LIGHT'], this represents the tint of the background, so DARK means background is dark, show header text as white.,
            containsTitle: <Boolean> Whether the splash has the page title in it.
          }
        },
        footerTint: <Boolean> Whether the footer should be tinted or not.
      }
    */
    this.setState({headerProperties: properties.header, footerTint: properties.footerTint});
  }

  render() {
    return (
      <div className="App">

        <Helmet>
                <title>Eyes of Love Psychotherapy</title>
                <meta name="description" content="Eyes of Love Psychotherapy is a holistic, integrative, and heartful counselling practice created by Josh Funfer and located on Salt Spring Island, BC. He offers both remote and in-person counselling sessions." />

                <meta name="og:title" content="Eyes of Love Psychotherapy" />
                <meta name="og:url" content={WEBSITE_URL} />
                <meta property="og:image" content={`${WEBSITE_URL}/images/OGImage.jpg`} />
                <meta property="og:description" content="Eyes of Love Psychotherapy is a holistic, integrative, and heartful counselling practice created by Josh Funfer and located on Salt Spring Island, BC. He offers both remote and in-person counselling sessions." />
                <meta property="og:type" content="website" />
        </Helmet>

        <Header properties={this.state.headerProperties} noJS={this.state.noJs} />
        <ScrollToTop />
        {/* Available Pages */}
        <div>
          <PageErrorBoundary>
            <Switch>
              <Route path="/" exact render={() => <Home noJS={this.state.noJ} registerPage={this.registerPageProperties} connectivity={this.state.apiConnectivity} />} />
              <Route path="/contact" render={() => <Contact registerPage={this.registerPageProperties} connectivity={this.state.apiConnectivity} noJS={this.state.noJs} />} />
              <Route path="/approach" render={() => <Approach registerPage={this.registerPageProperties} noJS={this.state.noJs} />} />
              <Route path="/faq" render={() => <FAQ registerPage={this.registerPageProperties} noJS={this.state.noJs} />} />
              <Route path="/events/:slug" render={(props) => <Event {...props} registerPage={this.registerPageProperties} noJS={this.state.noJs} />} />
              <Route path="/events" render={() => <Events registerPage={this.registerPageProperties} connectivity={this.state.apiConnectivity} noJS={this.state.noJs} />} />
              <Route path="/blog" render={() => <Blog registerPage={this.registerPageProperties} connectivity={this.state.apiConnectivity} noJS={this.state.noJs} />} />
              <Route path="/about" render={() => <About registerPage={this.registerPageProperties} noJS={this.state.noJs} />} />
              {/* <Route path="/newsletter/subscriber" render={(props) => <Subscriber {...props} registerPage={this.registerPageProperties} noJS={this.state.noJs} />} /> */}
              <Route path="/privacy-policy" render={(props) => (
                <React.Suspense fallback={<div style={{marginTop: '100px'}}>Loading...</div>}>
                  <PrivacyPolicy {...props} registerPage={this.registerPageProperties} />
                </React.Suspense>
              )} />

              {/* Catch all - shows 404 page */}
              <Route render={() => <PageNotFound registerPage={this.registerPageProperties} />} />
            </Switch>
          </PageErrorBoundary>
        </div>
        <Footer tint={this.state.footerTint}/>
      </div>
    );
  }
}

export default App;
