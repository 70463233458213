import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { IoWarningOutline } from 'react-icons/io5';
import { VscLoading } from 'react-icons/vsc';

import TextInput from '../../components/UI/TextInput';
import SuccessCheckmark from '../../components/UI/SuccessCheckmark'
import { API_URL, CONTACT_INFO, WEBSITE_URL } from '../../globals';
import styles from './Contact.module.css';
import { Helmet } from 'react-helmet-async';
import ArbutusFooter from '../../components/UI/ArbutusFooter';

const Contact = ({ registerPage, connectivity, noJS }) => {

    // set up state, formSent is used to pull the form off and display the 'form sent' checkmark
    const [formSent, setFormSent] = React.useState(false);
    const [formError, setFormError] = React.useState(null);

    

    // when the component mounts, register header information
    React.useEffect(() => {
        registerPage({
            header: {
                title: 'Contact',
                splash: {
                    tint: 'LIGHT',
                    containsTitle: true
                }
            },
            footerTint: true
        });
    }, [registerPage]);

    // // React reference to the hCaptcha
    // const captchaRef = React.useRef(null);

    // // Function for when the current captcha expires, loads a new one
    // const onCaptchaExpire = () => {
    //     captchaRef.current.resetCaptcha();
    // };
    
    // // function for when the Captcha errors
    // // Never had this one called before.
    // const onCaptchaError = (err) => {
    //     console.log(`hCaptcha Error: ${err}`);
    // };


    // handler for when the form submits, it sends the values to the api. If there are errors it sets
    // the appropriate error messages, otherwise it sets state.formSent to true which hides the form and
    // shows the success box
    // This function gets called by formik only when all form validation is complete.
    const submitMessage = (values, {setSubmitting, setErrors}) => {

        // Post the values to the api
        axios.post(`${API_URL}/contact`, {
            ...values,
            privacyPolicyAgreed: values.privacyPolicyAgreed ? 1 : 0
        })
        // if Successful then set formSent to true
        .then(response => {
            setFormSent(true);
            document.getElementById("after-contact-scroll")?.scrollIntoView();
        })
        // if the request failed
        .catch(error => {
            // If it failed with status 422 then data validation failed.
            // Loop through the server response and set the appropriate error messages
            if(error.response && error.response.status === 422) {
                console.log(error.response.data);
                let o = {};
                for(let e of error.response.data.errors) {
                    o[e.param] = e.msg;
                }
                setErrors(o);
                setFormError("One or more fields are invalid; please correct them before proceeding");
            }
            // Else some other network error occured. Show a generic error message on the form 
            else {
                setFormError("Oops, something went wrong. Please try again later.");
            }

            // scroll up to the top of the form to show error message
            document.getElementById("contact-form-header").scrollIntoView({behavior: 'smooth'});

            // Finally reset the captcha
            //captchaRef.current.resetCaptcha();
        })
        // Finally set submitting to false
        .finally(() => {
            setSubmitting(false);
        });
        
    }
    

    // Set-up formik with initial values, the submit handler, and the validation we want
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
            privacyPolicyAgreed: false,
        },
        onSubmit: submitMessage,
        validationSchema: Yup.object().shape({
            name: Yup.string().min(2, "Must be 2 characters or more").max(100, "Must be 100 characters or less"),
            email: Yup.string().required("Required Field").email('Invalid Email').min(5, "Must be 5 characters or more").max(150, "Must be 150 characters or less"),
            subject: Yup.string().min(2, "Must be 2 characters or more").max(200, "Must be 200 characters or less"),
            message: Yup.string().required("Required Field").min(5, "Must be 5 characters or more").max(100000, "Must be 100,000 characters or less"),
            privacyPolicyAgreed: Yup.bool().required().oneOf([true], "You must accept the privacy policy to continue")
        })
    });


    return(
        <div className={styles.Page}>
            <Helmet>
                <title>Contact | Eyes of Love</title>
                <meta name="description" content="Get in touch with Josh Funfer to book a session or to ask any questions you may have. All inquiries are welcome." />
                <meta name="keywords" content="eyes of love, therapy, therapist, book session, contact, help, questions, psychotherapy, psychotherapist" />

                <link rel="canonical" href={`${WEBSITE_URL}/contact/`} />

                <meta name="og:title" content="Contact Josh Funfer" />
                <meta name="og:url" content={`${WEBSITE_URL}/contact`} />
                {/* <meta property="og:image" content={`${WEBSITE_URL}/images/OGImage.jpg`} /> */}
                <meta property="og:description" content="Get in touch with Josh Funfer to book a session or to ask any questions you may have. All inquiries are welcome." />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className={styles.Main}>
            <div className={styles.Splash} style={{backgroundImage: 'url("/images/get_in_touch_v3.svg")'}} aria-label="Get in touch with Josh Funfer"></div>
            <div id="after-contact-scroll"></div>
            {!formSent ? <div className="container">
                
                <div className="row">
                    <div className="col" style={{textAlign: 'center'}}>
                        <p>Have questions? Want to book a session? Or just want to talk?</p>
                        <p>Please feel welcome to email me at <a href={`mailto:${CONTACT_INFO.email}`} title="Email Josh">{CONTACT_INFO.email}</a>{connectivity && !noJS ? ", " : " or "} call me at <a href={`tel:${CONTACT_INFO.phone.value}`} title="Call Josh">{CONTACT_INFO.phone.label}</a>{connectivity && !noJS ? ", or contact me via the form below." : "."}</p>
                        <p>All communications will be kept in the strictest confidence.</p>

                    </div>
                </div>


                {connectivity && !noJS ? <div>
                    <div className="row">
                        <div className="col">
                            <h1 style={{textAlign: 'center'}} id="contact-form-header">Contact Form</h1>

                            {Boolean(formError) ? <div className={styles.FormError}>
                                <span><IoWarningOutline /></span>
                                <p>{formError}</p>
                            </div> : null }
                        </div>
                    </div>

                    <form name="contact-form" onSubmit={formik.handleSubmit} noValidate id="contact-form">
                        <div className="row">
                            <div className="col-md-6">
                                    <TextInput
                                        name="name"
                                        label="Name"
                                        type="text"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        touched={formik.touched.name}
                                        error={formik.errors.name} />
                            </div>
                            <div className="col-md-6">
                                    <TextInput
                                        name="email"
                                        label="Email"
                                        type="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        touched={formik.touched.email}
                                        error={formik.errors.email} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextInput
                                    name="subject"
                                    label="Subject"
                                    type="text"
                                    value={formik.values.subject}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.subject}
                                    error={formik.errors.subject} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextInput
                                    name="message"
                                    label="Message"
                                    type="textarea"
                                    rows="10"
                                    value={formik.values.message}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.message}
                                    error={formik.errors.message} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextInput
                                    name="privacyPolicyAgreed"
                                    type="checkbox"
                                    value={formik.values.privacyPolicyAgreed}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.privacyPolicyAgreed}
                                    error={formik.errors.privacyPolicyAgreed}>
                                        <span>I have read and agree to the <a href="/privacy-policy" title="Open the privacy policy in a new tab" target="_BLANK" referrerPolicy="no-referrer">privacy policy</a>.</span>
                                </TextInput>
                            </div>
                        </div>
                       
                        <div className={styles.SubmitRow}>
                            <div>{!formik.isValid ? <IoWarningOutline /> : null}</div>
                            <div><input type="submit" value={formik.isSubmitting ? "Sending ..." : 'Send Message'} className={styles.SubmitButton} /></div>
                            <div>{formik.isSubmitting ? <VscLoading /> : null}</div>
                        </div>
                        <br /><br />
                    </form>
                </div> : null }
            </div>
            :
            // This shows the checkmark when the form is sent
            <div style={{display: 'flex', flexDirection: 'column', width: '90vw', maxHeight: '50vh', justifyContent: 'center', margin: '10px auto'}}>
                <SuccessCheckmark />
                <p style={{textAlign: 'center', marginTop: '0px', fontSize: '140%', color: '#666', fontWeight: 'bold'}}>Message Sent!</p>
                <p style={{textAlign: 'center'}}><Link to="/" title="Return to home page">Return to home</Link></p>
            </div> }
            </div>

            <div className={styles.FooterHolder}>
                <ArbutusFooter tint />
            </div>
        </div>
    );
};

export default Contact;