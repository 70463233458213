import React from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';

import styles from './UI.module.css';

class SplashDownChevron extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: true
        }
    }

    intervalReference = null;

    componentDidMount() {
        this.intervalReference = setInterval(() => {
            this.setState((prevProps) => ({show: !prevProps.show}));
        }, 3000);
    }

    componentWillUnmount() {
        if(this.intervalReference) clearTimeout(this.intervalReference);
    }

    scrollDownHandler = () => {
        document.getElementById("scroll-into-view").scrollIntoView({behavior: 'smooth'});
    }

    render() {
        return(
            <div id="scroll-into-view" className={this.props.light ? [styles.SplashDownChevron, styles.Light].join(" ") : styles.SplashDownChevron} onClick={this.scrollDownHandler} title="Scroll down">
                <BsChevronCompactDown className={this.state.show ? "show" : "hide"}/>
            </div>
        );
    }
}

export default SplashDownChevron;