import React from 'react';
import { Helmet } from 'react-helmet-async';
import SplashDownChevron from '../../components/UI/SplashDownChevron';

import styles from './About.module.css';
import ProfilePicture from './josh_profile.webp';

import { WEBSITE_URL} from '../../globals';

const About = ({ registerPage}) => {

    const [fullStoryOpen, setFullStoryOpen] = React.useState(false);
    const [noJS, setNoJS] = React.useState(false);

    // when the component mounts, register the header
    React.useEffect(() => {
        registerPage({
            header: {
                title: 'About Josh',
                splash: {
                    containsTitle: true,
                    tint: 'LIGHT'
                }
            },
            footerTint: false
        });

         // if the user agent is ReactSnap then we're building, set all offerings open so that non-js users see all content.
        try {
            if(navigator.userAgent === "ReactSnap") setNoJS(true);
            else setNoJS(false);
        } catch(e) {
           setNoJS(false);
        }
    }, [registerPage]);

    const toggleFullStory = () => {
        setFullStoryOpen(!fullStoryOpen);
    };

    return(
        <div className={styles.Page}>
            <Helmet>
                <title>About Josh | Eyes of Love</title>
                <meta name="description" content="Read about Josh Funfer and why he became a psychotherapist" />
                <meta name="keywords" content="Josh Funfer, Salt Spring, Salt Spring Island, psychotherapy, counselling" />

                <link rel="canonical" href={`${WEBSITE_URL}/about/`} />

                <meta name="og:title" content="About Josh Funfer" />
                <meta name="og:url" content={`${WEBSITE_URL}/about`} />
                {/* <meta property="og:image" content={`${WEBSITE_URL}/images/OGImage.jpg`} /> */}
                <meta property="og:description" content="Read Josh Funfer's story and why he became a psychotherapist" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className={styles.Splash}>
                <h1>My Story</h1>
                <SplashDownChevron />
            </div>

            <div className={[styles.ContentContainer, "container"].join(" ")}>
                <aside>
                    <img src={ProfilePicture} alt="Josh Funfer's profile" />
                    <h3>Josh Funfer</h3>
                </aside>
                <article>
                    <p>So much of my personal growth in this life has been the journey of dropping from my head down into my heart.</p>
                    <p>It always felt safer for me to think about my emotions and try to understand them than it did to actually feel them. I often felt overwhelmed by both my own emotions and by empathically feeling the emotions of the people around me. So, in order to cope with this, I closed down. I found great joy in thinking about the world and about humanity, however, I avoided feeling the fullness of what it is to be human. It wasn’t until I travelled to India and Nepal when I was 24 years old that I started to really open my heart. I realized then that in order to fully experience the great joy and beauty of life, I also had to open myself to the sorrow and hardships of life.</p>
                    <p>While I was still in Nepal, I applied to get my Master’s Degree in Integral Counselling Psychology, so that I could both continue to open my own heart and, above all, learn how to support others in opening their hearts. I realized there that nothing brings me more joy and fulfilment in life than helping people to view themselves and the world around them with an open heart. For me, this is what it means to see through eyes of love.</p>
                    <p>It is such a great honour for me to get to support others in connecting with their emotional experiences. It was a long and challenging journey for me to begin to feel what is in my heart, instead of just analysing these emotions from the safety of my head. Throughout my own inner journey of healing, I struggled with depression, crippling self-criticism, and deep-rooted feelings of worthlessness. During the darker times in my life, I was isolated and I found it almost impossible to accept myself. However, thanks to insightful and heartful guidance from counsellors, mentors, friends, and family I learned to view myself more gently and I have started to accept more of my imperfections. Now, I am here to help you experience the fullness of your emotions and to enjoy the full richness of life.</p>
                    <h3 style={{marginTop: '2em', marginBottom: '0px'}}>Full Story</h3>
                    <div className={(fullStoryOpen || noJS) ? styles.OpenStory : null}>
                        <p>I began this life as a highly imaginative child. I spent most of my childhood in magical fantasy worlds that I created, both by myself and with my friends. That was how I coped with the suffering and hardship of this world, which often overwhelmed me.</p>
                        <p>I have always been entranced by big questions, such as “what is the meaning of life?”, “can we make meaning from our suffering?”, “are we truly free to shape our own destiny” etc. So, I spent most of my teenage years and young adulthood pondering these questions. This led me to study Western Philosophy, Eastern Religions, and Social Psychology at McGill University for my undergraduate degree.</p>
                        <p>I learned so much at McGill University. The insights I gained there, the questions I explored, and the beliefs I developed, all helped me open my mind to the wonders of life and of humanity. However, I realized that I did not want to spend my life as an academic. Instead, I wanted to help people in a more concrete and personal way. This led me to study and practice holistic counselling for my master’s degree.</p>
                        <p>During my master’s degree, I co-taught a course called Therapeutic Communication with Barbara Morrill, PhD., for four semesters in a row. Every semester I would teach three classes, one on somatic or bodily-oriented psychotherapy, one on empathy, and one on trauma. I also gave talks throughout the course on topics such as: the deeper layers of emotional experiences, the importance of mindfulness, and self-compassion. However, the most amazing aspect of teaching for me was being a mentor and helping our students practice dropping from their heads into their hearts.</p>
                    </div>
                    {!noJS ? <p className={styles.ReadMore}>
                                <button onClick={toggleFullStory} title="Click to expand full story">{fullStoryOpen ? "Show Less" : "Read More"}</button>
                            </p> : null }
                    
                </article>
                
            </div>
            <div className={styles.Credentials} id="qualifications" style={{backgroundColor: 'rgb(85, 122, 151)'}}>
                <div className="container">
                    <h3>Credentials</h3>
                    <ul>
                        <li><b>Registered Clinical Counsellor</b> (RCC) of British Colombia.</li>
                        <li><b>Adjunct Professor</b> for the Integral Counselling Psychology (ICP) Master's program at The California Institute of Integral Studies.<br />I teach a foundational class, called "Therapeutic Communication," online. This course teaches graduate students the core therapeutic communication skills of: deep listening, presence, and helping clients drop from their head into their heart.</li>
                        <li><b>Masters of Arts</b> (M.A.) in Integral Counselling Psychology from The California Institute of Integral Studies (CIIS) in San Francisco, California.</li>
                        <li><b>Bachelors of Arts</b> (B.A.) - Joint-Honours in Western Philosophy and Eastern Religious Studies with a minor in Psychology from McGill University in Montreal, Quebec.</li>
                    </ul>
                </div>
            </div>
            <div className={styles.Credentials} style={{backgroundColor: 'rgb(73, 112, 131)'}}>
                <div className="container">
                    <h3>Work &amp; Volunteer Experience</h3>
                        <ul>
                            <li>Teacher’s Assistant for “Therapeutic Communication” - an introductory course in the Integral Counselling Psychology Masters program.</li>
                            <li>Psychotherapy Trainee at the Church St. Integral Counselling Center in San Francisco, California.</li>
                            <li>Student Ambassador and Workshop Facilitator for the "Respectful Relationships" program (R+R) at Gulf Islands Secondary School (GISS) on Salt Spring Island, British Colombia.</li>
                        </ul>
                </div>
            </div>
            <div className={styles.Credentials}>
                <div className="container">
                    <h3>Workshops and Trainings</h3>
                    <ul>
                        <li>"Presence-Based Training, Practice, &amp; Consultation Group" with Riyaz Motan (2020-2021)</li>
                        <li>"Somatic Experiencing Workshop on Healing Trauma" with Peter Levine, PhD. (2019)</li>
                        <li>"Discovering Natural Fulfillment Workshop" with Gangaji (2019)</li>
                        <li>"The Great Pilgrimage from Head to Heart" Workshop with John Prendergast (2018)</li>
                    </ul>
                </div>

                
            </div>

            <div className={styles.ImageFooterHolder}>
                <div className={[styles.ImageFooter, "image-footer"].join(" ")}></div>
            </div>
        </div>
    );
};

export default About;