export const SITE_NAME = "Eyes of Love Psychotherapy";

export const SITE_OWNER = "Josh Funfer";

export const API_URL = "https://www.eyesoflove.ca/a/api";

export const WEBSITE_URL = "https://www.eyesoflove.ca";



export const PRACTICE_ADDRESS = {
    road: '338 Lower Ganges Rd, Unit 208',
    city: 'Salt Spring Island',
    province: 'BC',
    country: 'Canada',
    postalCode: 'V8K2V3'
}

export const MAP_INFO = {
    geo: 'geo:48.859962080061955,-123.50903826468002; u=1',
    googleMaps: 'https://www.google.com/maps/place/Eyes+of+Love+Psychotherapy/@48.8596527,-123.511419,17z/data=!3m1!4b1!4m5!3m4!1s0x548f57485cc84475:0xa219facd07980c79!8m2!3d48.8596839!4d-123.5091986',
    appleMaps: 'https://maps.apple.com/?q=48.859962080061955,-123.50903826468002',
    appleMaps: 'https://maps.apple.com/?q=48.859962080061955,-123.50903826468002',
    openStreetMap: 'https://arodny.com'
}

export const CONTACT_INFO = {
    phone: {
        label: '1-250-537-7330',
        value: '+1-250-537-7330'
    },
    email: 'josh@eyesoflove.ca'
}
