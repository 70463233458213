import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    //const { pathname } = useLocation();
    const location = useLocation();

    useEffect(() => {
        if(location.hash) {
            let h = location.hash.replace("#", "");
            document.getElementById(h)?.scrollIntoView();
        }
        else window.scrollTo(0,0);
    }, [location]);

    return null;
}

export default ScrollToTop;