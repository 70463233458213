import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BiCalendarHeart } from 'react-icons/bi';
import { FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
// import Newsletter from '../../components/newsletter/NewsletterSignUp';
import ArbutusFooter from '../../components/UI/ArbutusFooter';

import { WEBSITE_URL} from '../../globals';
//import { dateToString } from '../../helpers/functions';


import styles from './Events.module.css';

const Events = ({registerPage, connectivity, noJS}) => {


    const event = {
        name: 'Seeing Through Eyes of Love',
        slug: 'seeing-through-eyes-of-love',
        date: Date.now,
    }

    // when the component mounts, register the header
    React.useEffect(() => {
        registerPage({
            header: {
                title: 'Events',
                splash: {
                    tint: 'LIGHT',
                    containsTitle: true,
                },
            },
            footerTint: true
        });
    }, [registerPage]);

    return(
        <div className={styles.Page}>
            <Helmet>
                <title>Events | Eyes of Love</title>
                <meta name="description" content="Learn about events hosted by Josh Funfer that will explore a wide range of psychological, spiritual, and human topics." />
                {/* <meta name="keywords" content="eyes of love, events, event, seminars, seminar, psychotherapy, therapy, psychology" /> */}

                <link rel="canonical" href={`${WEBSITE_URL}/events/`} />

                <meta name="og:title" content="Eyes of Love Events" />
                <meta name="og:url" content={`${WEBSITE_URL}/events`} />
                {/* <meta property="og:image" content={`${WEBSITE_URL}/images/OGImage.jpg`} /> */}
                <meta property="og:description" content="Learn about events hosted by Josh Funfer that will explore a wide range of psychological, spiritual, and human topics." />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="container">
                
                {/* <h2>Upcoming</h2> */}
                <div className={styles.CalendarIcon}><BiCalendarHeart /></div>
                <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: '0px', fontSize: '1.15em', marginBottom: '3em'}}>There are currently no upcoming events scheduled, but I will be offering more events in the coming months.<br />These talks and workshops will explore a wide range of psychological, spiritual, and human topics.<br />Stay tuned.</p>
                
                <h2 style={{marginBottom: '2em', borderBottom: '2px solid #888', paddingBottom: '15px'}}>Past Events</h2>
                {/* NOTE: This section needs css and accessablity clean-up */}
                <div className={styles.EventCard}>
                    <div className="row" style={{backgroundColor: 'rgba(0,0,0,0.05)', padding: '1em'}}>
                        <div className={styles.EventTitleBlock} >
                            <h2 style={{marginTop: '0px'}}>
                                <span style={{display: 'block', fontFamily: 'Eagle Lake'}}>Seeing Through Eyes of Love</span>
                                <span style={{display: 'block', fontSize: '0.8em', fontWeight: 'normal', fontFamily: 'Work Sans', marginTop: '0.5em'}}>A Practice for Cultivating Self-Awareness &amp; Self-Acceptance</span>
                            </h2>
                            <p></p>
                        </div>
                        <div className={[styles.EventDateBlock].join(" ")}>
                            <span style={{display: 'block', fontSize: '1.5em'}}>Jul 30, 2021</span>
                            <span style={{display: 'block', fontSize: '1.2em'}}>5:00 PM PST</span>
                            <span style={{display: 'block', fontSize: '1.25em', color: 'green'}}>FREE &bull; Live Online Talk</span>
                        </div>
                    </div>
                    <div style={{padding: '0.5em 1em 0px 1em'}}>
                        <p>I am giving this talk because I want to share a practice with you - the practice of seeing yourself and the world around you through eyes of love.</p>
                        <p>This practice is very close to my heart. It is both the foundation for my holistic counselling work, and it is the core of my spiritual practice.</p>
                        <p>In this talk, I will tell you about some of the most significant experiences, lessons, and insights in my life that helped me discover what it means to see through eyes of love. I will describe how viewing yourself through eyes of love can help you begin to cultivate self-awareness and self-acceptance. I will also explain some of the most important aspects of this practice and give you 3 practical tools to help you bring it into your daily life. Finally, I will lead a 15-minute guided meditation to help you experience what it is like to see yourself through eyes of love.</p>
                    </div>
                    <div className={styles.EventButtonBottom}>
                        <a href="https://www.youtube.com/watch?v=EbM1N0wJvGM"
                        target="_BLANK"
                        rel="noreferrer"
                        title="Watch a recording of this talk for free on Youtube" className={[styles.ButtonLink, styles.YoutubeButtonLink].join(" ")}><FaYoutube /> Watch on Youtube</a>
                        <Link to={`/events/${event.slug}`} className={[styles.ButtonLink].join(" ")} title="View more details about this event">More Details</Link>
                        
                        {/* <Link to={`/events/${event.slug}#registration`} className={styles.ButtonLink} title="Register for this event">Register</Link> */}
                    </div>
                    
                </div>
                <br /><br />
                {/* Begin Newsletter component */}
                {/* {connectivity && !noJS ?
                    <div className={styles.NewsletterSignup}>
                        <p>Want event updates?</p>
                        <h2>Join my newsletter</h2>
                        <Newsletter topic="events" />
                    </div>
                : null } */}
                {/* End Newsletter Component */}
            </div>
            <div className={styles.FooterHolder}>
                <ArbutusFooter tint />
            </div>
        </div>
    );
};

export default Events;