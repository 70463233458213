import React from 'react';
import { Link } from 'react-router-dom';
import SplashDownChevron from '../../components/UI/SplashDownChevron';
import { Helmet } from 'react-helmet-async';

import styles from './Home.module.css';
import ProfilePicture from '../about/josh_profile.webp';
import { GenerateMapLinks } from '../../components/GenerateMapLinks';
import { PRACTICE_ADDRESS, CONTACT_INFO, WEBSITE_URL, MAP_INFO } from '../../globals';
import PhotoGallery from '../../components/photo-gallery/PhotoGallery';


const photoGalleryLibrary = [
    {
        thumbnailURL: '/images/office-photos/Counselling_Room_Thumbnail.webp',
        regularURL: '/images/office-photos/Counselling_Room_Large.webp',
        title: 'The Counselling Room',
        description: 'Counselling Room'
    },
    {
        thumbnailURL: '/images/office-photos/Desk_Thumbnail.webp',
        regularURL: '/images/office-photos/Desk_Large.webp',
        title: 'Josh\'s Office',
        description: 'Office'
    },
    {
        thumbnailURL: '/images/office-photos/Waiting_Area_Thumbnail.webp',
        regularURL: '/images/office-photos/Waiting_Area_Large.webp',
        title: 'The Practice Waiting Area',
        description: 'Waiting Area'
    },
    {
        thumbnailURL: '/images/office-photos/Forest_Thumbnail.webp',
        regularURL: '/images/office-photos/Forest_Large.webp',
        title: 'Decorative corner showing art and a plant'
    }
];

const Home = ({ registerPage, noJS, connectivity }) => {

    React.useEffect(() => {
        registerPage({
            header: {
                title: null,
                splash: {
                    tint: 'LIGHT',
                    containsTitle: true,
                    isHome: true
                }
            },
            footerTint: false
        });
    }, [registerPage]);

    const [fullStoryOpen, setFullStoryOpen] = React.useState(false);

    const [galleryOpen, setGalleryOpen] = React.useState(false);

    const toggleFullStory = () => {
        setFullStoryOpen(!fullStoryOpen);
    };

    

    return(
        <div className={styles.Page}>
            <Helmet>
                <link rel="canonical" href={WEBSITE_URL} />
                <meta name="keywords" content="psychotherapy, psychotherapist, therapy, therapist, Josh Funfer, Salt Spring, Salt Spring Island, counselling, support, mental health" />
            </Helmet>
    
            <div className={styles.BackgroundImage}></div>
            <div className={styles.Splash}>
                <div>
                    <div className={styles.Logo}></div>
                    <h1>Eyes of Love Psychotherapy</h1>
                </div>
                <p>Helping you look with love upon all the unhealed parts of yourself.</p>
                <SplashDownChevron />
            </div>
            
            <div className={styles.Quote}>
                <p>Nothing brings me more joy and fulfilment in life than helping people to view themselves and the world around them with an open heart. For me, this is what it means to see through eyes of love.</p>
            </div>
            <div className="container">
                <div className={styles.TileSet}>
                
                   
                    <div className={styles.Row}>
                        <div>
                        <div className={styles.Tile}>
                            <h2>The Practice</h2>
                            <p>Eyes of Love Psychotherapy is a holistic, integrative, and heartful counselling practice created by Josh Funfer and located on Salt Spring Island, BC. Josh offers both remote and in-person counselling sessions.</p>

                            <div className={styles.WorldMapBlock} style={{backgroundImage: "url('/images/world_map_outline_eol.png')"}}>
                                {/* <blockquote>Serving Salt Spring Island and Beyond</blockquote> */}
                            </div>
                            <blockquote className={styles.WorldMapQuote}>Serving Salt Spring Island and Beyond</blockquote>
                            <div className={[styles.QuickLinkPanel, styles.ButtonLink].join(" ")}>
                                <div className={styles.QuickLink}>
                                    <Link to="/approach" title="Discover my approach to psychotherapy">Discover my approach</Link>
                                </div>
                                <div className={styles.QuickLink}>
                                    <Link to="/faq" title="Read my frequently asked questions">Frequently asked questions</Link>
                                </div>
                                <div className={styles.QuickLink}>
                                    <Link to="/contact" title="Get in touch for a free consultation or if you have questions">Schedule a free consultation</Link>
                                </div>
                            </div>
                            
                                <p className={styles.GetInTouch}>Please feel welcome to email me at <a href={`mailto:${CONTACT_INFO.email}`} title="Email Josh">{CONTACT_INFO.email}</a>{connectivity ? ", " : " or "} call me at <a href={`tel:${CONTACT_INFO.phone.value}`} title="Call Josh">{CONTACT_INFO.phone.label}</a>{connectivity && !noJS? <span>, or reach out through my <Link to="/contact" title="Get in touch with me">contact page</Link>.</span> : "."}</p>
                                
                        </div>


                        <div className={styles.Tile} style={{marginTop: '30px'}}>
                            <h2>About my Work</h2>
                            <div className={(fullStoryOpen || noJS) ? [styles.OpenStory, styles.Story].join(" ") : styles.Story}>
                                <p>We all long to be truly <i>seen</i> and accepted for who we are. And yet, have you ever found that it is easier to accept other people than it is to accept yourself? Many of us have been taught from a young age that we need to criticize ourselves in order to become successful in life. We have been conditioned to believe that it is acceptable, and even necessary, to judge ourselves negatively. When we judge a part of who we are, we usually reject that part. However, these rejected parts of ourselves often have a hidden gift to offer us. By accepting them, we can receive their gifts and return to a state of Wholeness. I can help you begin to recognize and accept these forgotten parts of yourself.</p>
                                <p>When we receive love, support, and respect, we blossom and flourish like a flower nourished by ample sunlight and water. Whereas, in the absence of love, support, and respect, we wilt like a flower that has been deprived of this essential nourishment.</p>
                                <p>Here is the heart of it: nothing is more nourishing than our own self-love and self-respect.</p>
                                <p>My work is rooted in the belief that we all have the innate capacity to heal ourselves. This innate capacity can flourish when we are given enough space, safety, and support. I am here to support you on your inner journey of self-discovery and healing.</p>
                                <p>Together we can slow things down and work in the present moment to recognize the wounds and past traumas within you that are calling out for help and healing. In slowing down, we will also begin to notice and embrace the innate strengths, capacities, and positive qualities that lay dormant within you.</p>
                                <p>Habitual patterns, negative beliefs, and deep-rooted fears begin to transform when they are met with gentle curiosity and openness. This quality of openness is what we cultivate when we practice loving, open-hearted awareness. If you choose to work with me, we will learn together how to look with open-hearted awareness upon any and all aspects of yourself that yearn to be seen, respected, and loved. This is what Eyes of Love Psychotherapy is all about.</p>
                            </div>
                            {!noJS ? <p className={styles.ReadMore}>
                                <button onClick={toggleFullStory} title="Click to expand full story">{fullStoryOpen ? "Show Less" : "Read More"}</button>
                            </p> : null }
                        </div>
                        </div>
                        <aside className={styles.Aside}>
                            <div className={styles.Profile + " " + styles.Tile}>
                                    <div className={styles.Picture}>
                                        <img src={ProfilePicture} alt="Josh Funfer" />
                                    </div>
                                    <div className={styles.ButtonLink}>
                                        <h3>Josh Funfer</h3>

                                        <p><i>Registered Clinical Counsellor</i></p>
                                        <p><i>M.A. in Integral Counselling Psychology</i></p>

                                    
                                        <Link to="/about" title="Learn more about Josh">Read my story</Link>
                                    </div>
                            </div>
                            <div className={[styles.Tile, styles.MapTile].join(" ")}>
                                <h2>Office Location</h2>
                                <p><a href={MAP_INFO.googleMaps} title="View location on Google Maps" target="_BLANK" rel="noreferrer">{PRACTICE_ADDRESS.road}<br />
                                {PRACTICE_ADDRESS.city}, {PRACTICE_ADDRESS.province}</a></p>
                                <p className={styles.ButtonLink}><GenerateMapLinks /></p>
                            </div>


                            <div className={[styles.Tile, styles.OfficePhotosDiv].join(" ")}>
                                <h2>Office Photos</h2>
                                
                                <div style={{backgroundImage: "url('/images/office-photos/Counselling_Room_Thumbnail.webp')"}} onClick={() => setGalleryOpen(true)}>
                                    <button onClick={() => setGalleryOpen(true)}>View Gallery</button>
                                </div>
                                <PhotoGallery open={galleryOpen} clickAwayHandler={() => setGalleryOpen(false)} library={photoGalleryLibrary} />
                            </div>


                            <div className={[styles.Tile, styles.MapTile].join(" ")}>
                                <h2>Opening Hours</h2>
                                <p>Eyes of Love Psychotherapy is open <span className="bold">Monday through Thursday,<br /> 11am - 7pm</span>.</p>
                                <p>I am available by appointment both for free initial consultations and for ongoing weekly sessions.</p>
                            </div>
                        </aside>
                        
                    </div>

                </div>
            </div>
            <div className={styles.ImageFooterHolder}>
                <div className={[styles.ImageFooter, "image-footer"].join(" ")}></div>
            </div>
        </div>
    );
}

export default Home;