const Intuitive = () => {
    return(
        <svg width="16mm" height="16mm" viewBox="0 0 23.956158 23.951138">
            <g transform="translate(-61.343761,-10.034859)">
                <path
                    style={{fill: '#000', stroke: 'none'}}
                    d="m 79.5214,33.936 c -0.1741,-0.1754 0.549,-1.2813 1.6067,-2.4578 3.6155,-4.0213 4.3455,-9.1701 1.9729,-13.9178 -1.5716,-3.1451 -3.6858,-5.0528 -6.7141,-6.0586 -5.3598,-1.7803 -11.5021,1.0501 -13.4625,6.2035 -1.6967,4.46 0.7818,10.4674 4.9715,12.0504 1.588,0.6 4.7269,0.5993 6.3123,0 5.2034,-1.9716 6.2887,-9.5298 1.7643,-12.2882 -3.3048,-2.0151 -7.5022,-0.359 -8.0648,3.182 -0.2236,1.4055 -0.077,1.8091 1.0796,2.9652 1.6254,1.6254 2.8165,1.6976 3.9629,0.2399 0.9877,-1.2556 1.3963,-1.3841 1.3963,-0.4392 0,2.0718 -3.6746,3.1561 -5.5493,1.6376 -2.3989,-1.9433 -2.6071,-5.0738 -0.4907,-7.3779 3.2691,-3.5591 8.8111,-2.4465 10.9177,2.1916 0.9992,2.2004 1.0393,3.7414 0.151,5.869 -1.6885,4.0485 -6.1489,6.3956 -10.2295,5.3829 -2.9249,-0.726 -5.0327,-2.3435 -6.4843,-4.976 -3.1984,-5.7997 -0.3503,-12.9905 6.1245,-15.4633 2.9126,-1.1122 7.4404,-0.7486 10.1027,0.8115 2.5519,1.4956 4.8961,4.2576 5.7564,6.7827 0.8829,2.5912 0.8717,6.8472 -0.025,9.4791 -0.7307,2.1445 -4.5327,6.7561 -5.0991,6.1848 z"
                    id="path5156-5" />
            </g>
        </svg>
    );
};

export default Intuitive;