
import styles from './UI.module.css';

const ArbutusFooter = ({tint = false}) => {

    let classes = ["image-footer", styles.ArbutusFooter];
    if(tint) classes.push(styles.ArbutusFooterTinted);
    
    return(
        <div className={classes.join(" ")}></div>
    );
};

export default ArbutusFooter;